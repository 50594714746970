<template>
  <component :is="tag" class="facility-attributes">
    <div class="section-inner">
      <h2 v-if="blok.title" class="title size-xl align-center">
        {{ blok.title }}
      </h2>
      <div
        v-if="blok.title && text?.length"
        class="heading size-m--d"
        v-html="text"
      ></div>
      <div
        v-if="!blok.title && text?.length"
        class="heading size-l"
        v-html="text"
      ></div>
      <div
        v-if="isDesktop && categoriesLength"
        :class="`facility-categories 
        ${categoriesLength === 4 ? 'has-4-categories' : ''} 
        ${categoriesLength === 5 ? 'has-5-categories' : ''} 
        ${blok.title || text?.length ? 'margin-top' : ''} `"
      >
        <StoryblokComponent
          v-for="category in blok.categories"
          :key="category._uid"
          class="facility-category"
          :blok="category"
        />
      </div>
      <div
        v-if="!isDesktop && categoriesLength"
        class="carousel-container"
      >
        <GenericCarousel
          v-if="categoriesLength > 1"
          :items="blok.categories"
          slides-per-view="auto"
          child-classes="facility-category"
          :space-between="14"
          class="carousel-navigation-bottom"
        />
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok } = toRefs(props); // eslint-disable-line
const { text } = useRichText(blok.value);
const categoriesLength = blok.value.categories?.length;
const isDesktop = inject("isDesktop");
</script>

<style lang="scss">
.background-facility-attributes.alternate {
  @include pair-1;
  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      background-color: var(--solid-01);
    }
  }
}
</style>

<style lang="scss" scoped>
.title {
  margin-bottom: 0.75rem;
}
.facility {
  &-categories {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-gap: 0.83rem;
    @include for-desktop-up {
      flex-wrap: wrap;
    }
  }
}
@include for-desktop-up {
  .has-4-categories .facility-category,
  .has-5-categories .facility-category:first-child,
  .has-5-categories .facility-category:nth-child(2) {
    grid-column: span 9;
  }
}
</style>
